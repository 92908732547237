'use strict';
/*
 the rule to manage errorCodes of KD.
 if we need new one, check http response code document first. 
 then if the document doesn't include suitable one for the error case, add it on extended part.
 if include but the meaning is little different, add it on extended part but include the http code
*/
export const GWDistPhase = {
    DEVEL: 'Dev',
    STAGE: 'Stg',
    PRODUCTION: 'Prod',
};
