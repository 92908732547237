<template>
  <!-- <div class="container-fluid py-2">
      <div class="card shadow">
        <div class="card-body">
          <div class="card-body">
              <div class="mx-auto">
              <div class="form-group">
                  <b-form-select
                  class="form-control form-control-lg"
                  v-model="selected"
                  :options="options"
                  ></b-form-select>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div> -->
  <div class="container-fluid py-2">
    <div class="card shadow" style="min-height: 100vh">
      <iframe
        src="https://ilps.naviensmartcontrol.com:6999/#/link-state"
        class="card-body"
      >
      </iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "network-diagnostic",
  components: {},
  data() {
    return {
      selected: null,
      options: [],
    };
  },
  methods: {},
  async created() {},
  updated() {},
  beforeUnmount() {},
};
</script>
