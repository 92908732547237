<template>
  <div class="col p-1" :class="order">
    <div class="card overflow-hidden">
      <div class="card-header pb-0" :style="backgroundColor">
        <div class="d-flex align-items-center align-middle">
          <h6 class="me-auto my-auto text-center text-truncate">
            {{ complexName }}
          </h6>
          <div>
            <span
              v-if="serverType == 'ILPS'"
              class="badge badge-sm bg-danger"
              >{{ serverType }}</span
            >
            <span
              v-else-if="serverType == 'KDHN'"
              class="badge badge-sm bg-warning"
              >{{ serverType }}</span
            >
            <span v-else class="badge badge-sm bg-info">{{ serverType }}</span>
          </div>
          <div class="ms-1">
            <span v-if="ip == ''" class="badge badge-sm bg-danger"
              >Unopened</span
            >
            <span v-else-if="isConnected" class="badge badge-sm bg-info"
              >Online</span
            >
            <span v-else class="badge badge-sm bg-warning">Offline</span>
          </div>
        </div>
        <div class="d-flex justify-content-end align-items-center pt-1">
          <div
            v-if="isLoading"
            class="spinner-border spinner-border-sm me-auto text-primary"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <a
            ref="warningPopover"
            data-bs-toggle="popover"
            data-bs-title="경고!"
            data-bs-content=""
            :style="{ display: isWarning ? 'block' : 'none' }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#dc3545"
              class="ms-1"
              viewBox="0 0 48 48"
            >
              <path
                d="M2 42 24 4l22 38Zm5.2-3h33.6L24 10Zm17-2.85q.65 0 1.075-.425.425-.425.425-1.075 0-.65-.425-1.075-.425-.425-1.075-.425-.65 0-1.075.425Q22.7 34 22.7 34.65q0 .65.425 1.075.425.425 1.075.425Zm-1.5-5.55h3V19.4h-3Zm1.3-6.1Z"
              />
            </svg>
          </a>
          <a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#172b4d"
              class="ms-2"
              viewBox="0 0 48 48"
            >
              <path
                d="M6 42V27h3v9.9L36.9 9H27V6h15v15h-3v-9.9L11.1 39H21v3Z"
              />
            </svg>
          </a>
          <a>
            <svg
              v-if="itsName && itsName != '' && itsName.length > 0"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#172b4d"
              class="ms-2"
              viewBox="0 0 48 48"
            >
              <path
                d="M9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h19.7v3H9v30h30V19.3h3V39q0 1.2-.9 2.1-.9.9-2.1.9Zm7.05-7.85v-3H32v3Zm0-6.35v-3H32v3Zm0-6.35v-3H32v3ZM34.6 17.8v-4.4h-4.4v-3h4.4V6h3v4.4H42v3h-4.4v4.4Z"
              />
            </svg>
          </a>
          <a
            v-if="itsName && itsName != '' && itsName.length > 0"
            :href="'https://its.kdiwin.com:8080/browse/' + itsName"
            target="_blank"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="#172b4d"
              class="ms-2"
              viewBox="0 0 48 48"
            >
              <path
                d="M22.5 34H14q-4.15 0-7.075-2.925T4 24q0-4.15 2.925-7.075T14 14h8.5v3H14q-2.9 0-4.95 2.05Q7 21.1 7 24q0 2.9 2.05 4.95Q11.1 31 14 31h8.5Zm-6.25-8.5v-3h15.5v3ZM25.5 34v-3H34q2.9 0 4.95-2.05Q41 26.9 41 24q0-2.9-2.05-4.95Q36.9 17 34 17h-8.5v-3H34q4.15 0 7.075 2.925T44 24q0 4.15-2.925 7.075T34 34Z"
              />
            </svg>
          </a>
        </div>
      </div>
      <div class="card-body" :style="backgroundColor">
        <div class="d-flex ms-auto align-center">
          <!-- <h6 class="me-auto my-auto text-sm">건설사</h6> -->
          <span class="my-auto text-xs font-weight-bold text-truncate">{{
            constructorName
          }}</span>
        </div>
        <div class="d-flex ms-auto align-center">
          <!-- <h6 class="me-auto my-auto text-sm">주소</h6> -->
          <span class="my-auto text-xs font-weight-bold text-truncate">{{
            address
          }}</span>
        </div>
        <!-- <div class="d-flex ms-auto align-center">
        <h6 class="me-auto my-auto text-sm">IP</h6>
        <span class="my-auto text-xs font-weight-bold">{{ ip }}</span>
      </div> -->
        <div class="d-flex align-items-center mx-auto align-middle">
          <h6 class="me-auto my-auto text-sm">CPU</h6>
          <span class="me-1 text-xs font-weight-bold">{{ metrics.cpu }}% </span>
          <div class="progress w-40">
            <div
              class="progress-bar"
              role="progressbar"
              v-bind:aria-valuenow="metrics.cpu"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="{
                width: metrics.cpu + '%',
                'background-color': progressCpuColor,
              }"
            ></div>
          </div>
        </div>
        <div class="d-flex align-items-center mx-auto align-middle">
          <h6 class="me-auto my-auto text-sm">Memory</h6>
          <span class="me-1 text-xs font-weight-bold"
            >{{ metrics.memory }}%
          </span>
          <div class="progress w-40">
            <div
              class="progress-bar"
              role="progressbar"
              v-bind:aria-valuenow="metrics.memory"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="{
                width: metrics.memory + '%',
                'background-color': progressMemoryColor,
              }"
            ></div>
          </div>
        </div>
        <div class="d-flex align-items-center mx-auto align-middle">
          <h6 class="me-auto my-auto text-sm">C Disk</h6>
          <span class="me-1 text-xs font-weight-bold"
            >{{ metrics.cDrive }}%
          </span>
          <div class="progress w-40">
            <div
              class="progress-bar"
              role="progressbar"
              v-bind:aria-valuenow="metrics.cDrive"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="{
                width: metrics.cDrive + '%',
                'background-color': progressCDriveColor,
              }"
            ></div>
          </div>
        </div>
        <div class="d-flex align-items-center mx-auto align-middle">
          <h6 class="me-auto my-auto text-sm">D Disk</h6>
          <span class="me-1 text-xs font-weight-bold"
            >{{ metrics.dDrive }}%
          </span>
          <div class="progress w-40">
            <div
              class="progress-bar"
              role="progressbar"
              v-bind:aria-valuenow="metrics.dDrive"
              aria-valuemin="0"
              aria-valuemax="100"
              :style="{
                width: metrics.dDrive + '%',
                'background-color': progressDDriveColor,
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*
TODO
ip가 없을 경우 deeming처리
*/

import { ApiManager } from "@/api/apiManager";
import { Popover } from "bootstrap";

export default {
  name: "card-complex",
  data() {
    return {
      isConnected: false,
      metrics: {
        cpu: undefined,
        memory: undefined,
        cDrive: undefined,
        dDrive: undefined,
      },
      isLoading: false,
      isWarning: false,
      warningMessages: [],
      refreshStatusTimer: undefined,
      popover: undefined,
    };
  },
  props: {
    complexId: {
      type: String,
      required: true,
    },
    complexName: {
      type: String,
      required: true,
    },
    constructorName: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      required: true,
    },
    ip: {
      type: String,
      required: true,
    },
    serverType: {
      type: String,
      required: true,
    },
    itsName: {
      type: String,
    },
  },
  computed: {
    order: function () {
      //   if (this.isBookmark) return "order-1";
      if (!this.isConnected) return "order-2";
      if (
        this.metrics.cpu >= 90 ||
        this.metrics.memory >= 90 ||
        this.metrics.cDrive >= 90 ||
        this.metrics.dDrive >= 90
      )
        return "order-3";
      if (
        this.metrics.cpu >= 80 ||
        this.metrics.memory >= 80 ||
        this.metrics.cDrive >= 80 ||
        this.metrics.dDrive >= 80
      )
        return "order-4";
      if (
        this.metrics.cpu >= 70 ||
        this.metrics.memory >= 70 ||
        this.metrics.cDrive >= 70 ||
        this.metrics.dDrive >= 70
      )
        return "order-5";
      return "order-last";
    },
    backgroundColor: function () {
      let color = "#FFFFFF";
      if (this.ip == "" || !this.isConnected) color = "#BDBDBD";
      else if (
        this.metrics.cpu >= 90 ||
        this.metrics.memory >= 90 ||
        this.metrics.cDrive >= 90 ||
        this.metrics.dDrive >= 90
      )
        color = "#EF9A9A";
      else if (
        this.metrics.cpu >= 80 ||
        this.metrics.memory >= 80 ||
        this.metrics.cDrive >= 80 ||
        this.metrics.dDrive >= 80
      )
        color = "#FFAB91";
      else if (
        this.metrics.cpu >= 70 ||
        this.metrics.memory >= 70 ||
        this.metrics.cDrive >= 70 ||
        this.metrics.dDrive >= 70
      )
        color = "#FFF9C4";
      return { "background-color": color };
    },
    progressCpuColor: function () {
      return this.getProgressBarColor(this.metrics.cpu);
    },
    progressMemoryColor: function () {
      return this.getProgressBarColor(this.metrics.memory);
    },
    progressCDriveColor: function () {
      return this.getProgressBarColor(this.metrics.cDrive);
    },
    progressDDriveColor: function () {
      return this.getProgressBarColor(this.metrics.dDrive);
    },
  },
  methods: {
    refresh: async function () {
      if (this.ip != "") {
        this.isLoading = true;

        this.refreshStatusTimer = setTimeout(async () => {
          await this.refresh();
        }, 300 * 1000);

        let health = await ApiManager.getInstance().getComplexHealth(
          this.complexId
        );
        if (health && health.data) {
          this.isConnected = health.data.isConnected;
          this.$emit("onPropsUpdated", {
            complexId: this.complexId,
            isConnected: this.isConnected,
          });
        }

        let metrics = await ApiManager.getInstance().getComplexMetrics(
          this.complexId
        );
        if (metrics && metrics.data) {
          if (metrics.data.cpu && metrics.data.cpu.length > 0)
            this.metrics.cpu = Math.round(metrics.data.cpu[0].Average);
          if (metrics.data.memory && metrics.data.memory.length > 0)
            this.metrics.memory = Math.round(metrics.data.memory[0].Average);
          if (metrics.data.cDrive && metrics.data.cDrive.length > 0)
            this.metrics.cDrive =
              100 - Math.round(metrics.data.cDrive[0].Average);
          if (metrics.data.dDrive && metrics.data.dDrive.length > 0)
            this.metrics.dDrive =
              100 - Math.round(metrics.data.dDrive[0].Average);
          this.$emit("onPropsUpdated", {
            complexId: this.complexId,
            metrics: this.metrics,
          });
        }
        // build warning message
        this.isWarning = false;
        this.warningMessages = [];
        if (
          !this.isConnected &&
          (this.metrics.cpu != undefined ||
            this.metrics.memory != undefined ||
            this.metrics.cDrive != undefined ||
            this.metrics.dDrive != undefined)
        ) {
          this.isWarning = true;
          this.warningMessages.push("IP주소 및 방화벽 점검이 필요합니다.");
        }
        this.isLoading = false;
      }
    },
    getProgressBarColor: function (value) {
      let color = "#283593";
      if (value >= 90) color = "#D50000";
      else if (value >= 80) color = "#EF6C00";
      else if (value >= 70) color = "#FDD835";
      return color;
    },
    getPopoverMessage: function () {
      return this.warningMessages.join("<br/>");
    },
  },
  async created() {
    await this.refresh();
  },
  mounted() {
    this.popover = new Popover(this.$refs.warningPopover, {
      html: true,
      trigger: "hover",
      content: this.getPopoverMessage,
    });
  },
  beforeUnmount() {
    if (this.refreshStatusTimer) {
      window.clearTimeout(this.refreshStatusTimer);
    }
    this.popover = undefined;
  },
};
</script>
